$form-control-height: 40px;

@import 'smart.checkbox';
@import "colors";

body, html {
  padding: 0;
  margin: 0;
  background-color: #f8f8f9;
  font-family: "Open Sans", "Helvetice Neue", sans-serif;
  color: #445259;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}
app-root {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  display: block;
}

* {
  margin: 0
}

/** Smart overwrites **/
smart-grid[theme=custom].smart-element {
  --smart-primary: rgb(234, 234, 234, 1);
  --smart-ui-state-active: rgba(234, 234, 234, 1);
  --smart-ui-state-border-active: rgba(220, 220, 220, 1);
  --smart-primary-color: rgba(68, 82, 89, 1);
  --smart-ui-state-color-active: rgba(68, 82, 89, 1);
  --smart-background-color: rgba(68, 82, 89, 1);
  --smart-background: rgba(248, 248, 249, 1);
  --smart-surface-color: rgba(68, 82, 89, 1);
  --smart-surface: rgba(248, 248, 249, 1);
  --smart-border: rgba(224, 224, 224, 0);
  --smart-border-radius: 5px;
  --smart-border-top-left-radius: 5px;
  --smart-border-top-right-radius: 5px;
  --smart-border-bottom-left-radius: 5px;
  --smart-border-bottom-right-radius: 5px;
  --smart-ui-state-color-hover: rgba(51, 51, 51, 1);
  --smart-ui-state-hover: rgba(234, 234, 234, 1);
  --smart-ui-state-border-hover: rgba(234, 234, 234, 1);
  --smart-ui-state-color-selected: rgba(68, 82, 89, 1);
  --smart-ui-state-selected: rgba(234, 234, 234, 1);
  --smart-ui-state-color-focus: rgba(0, 0, 0, 1);
  --smart-ui-state-focus: rgba(224, 224, 224, 1);
  --smart-outline: rgba(224, 224, 224, 1);
}

smart-grid:not([selection_allow-cell-selection])[focus] smart-grid-row[focus] smart-grid-cell:not([editor]):not([header]), smart-grid:not([selection_allow-cell-selection])[focus] smart-grid-row[focus] smart-grid-cell:not([editor]):not([header]) .smart-label:not([style]), smart-grid:not([selection_allow-cell-selection])[focus][appearance_show-selection-on-top] smart-grid-row[focus] smart-grid-cell:not([editor]):not([header]), smart-grid:not([selection_allow-cell-selection])[focus][appearance_show-selection-on-top] smart-grid-row[focus] smart-grid-cell:not([editor]):not([header]) .smart-label {
  background: rgba(220, 220, 220,.7)!important;
  color: var(--smart-primary-color)!important;
  border-color: var(--smart-primary)!important;
}

// this fixes the cell text to move down 1px on select due to adding a border-top
smart-grid-row smart-grid-cell .smart-label {
  border-top: none !important;
}

smart-grid[theme=custom].smart-element .smart-grid-column-header{
  --smart-surface-color: rgba(68, 82, 89, 1);
  --smart-surface: rgba(248, 248, 249, 1);
  --smart-grid-column-horizontal-border: rgba(68, 82, 89, 1);
  --smart-grid-column-vertical-border: rgba(224, 224, 224, 1);
  --smart-ui-state-color-hover: rgba(51, 51, 51, 1);
  --smart-ui-state-hover: rgba(234, 234, 234, 1);
  --smart-ui-state-border-hover: rgba(234, 234, 234, 1);
  --smart-grid-column-font-size: inherit;
  --smart-grid-column-font-weight: inherit;
}

smart-grid {
  &.page-content-grid {
    --smart-background: #f9f9f9;
    --smart-grid-default-height: auto;
    height: calc(100vh - 220px);
  }
}

/* Default modal window screen top position */
smart-window[theme=custom] {
  top: 5vh !important;
  &:focus {
    border: none;
  }
}

smart-window[theme=custom].smart-element {
  --smart-primary: rgba(112, 112, 112, 1);
  --smart-ui-state-active: rgba(112, 112, 112, 1);
  --smart-ui-state-border-active: rgba(112, 112, 112, 1);
  --smart-primary-color: rgba(255, 255, 255, 1);
  --smart-ui-state-color-active: rgba(255, 255, 255, 1);
  --smart-background-color: rgba(68, 82, 89, 1);
  --smart-background: rgba(248, 248, 249, 1);
  --smart-surface-color: rgba(68, 82, 89, 1);
  --smart-surface: rgba(248, 248, 249, 1);
  --smart-border: rgba(224, 224, 224, 1);
  --smart-border-radius: 5px;
  --smart-border-top-left-radius: 5px;
  --smart-border-top-right-radius: 5px;
  --smart-border-bottom-left-radius: 5px;
  --smart-border-bottom-right-radius: 5px;
  --smart-ui-state-color-hover: rgba(51, 51, 51, 1);
  --smart-ui-state-hover: rgba(235, 235, 235, 1);
  --smart-ui-state-border-hover: rgba(235, 235, 235, 1);
  --smart-ui-state-color-selected: rgba(220, 220, 220, 1);
  --smart-ui-state-selected: rgba(220, 220, 220, 1);
  --smart-ui-state-color-focus: rgba(0, 0, 0, 1);
  --smart-ui-state-focus: rgba(224, 224, 224, 1);
  --smart-outline: rgba(224, 224, 224, 1);
}

smart-tabs {
  .smart-container {
    .smart-tab-item {
      .smart-container {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .smart-tab-strip {
      border: 0;
      line-height: 30px;
      border-radius: 5px;
    }
    .smart-tabs-content-section {
      border: 0;
    }
    .smart-tabs-header-section {
      border: none !important;
      background-color: #eaeaea;
    }
    .smart-tabs-selection-bar {
      background-color: $primary-red;
    }
    .smart-tab-label-container:first-child {
      margin-left: 0 !important;
    }
    .smart-tab-label-container {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      &.smart-tab-selected {
        background-color: transparent;
        color: $primary-red !important;
      }
    }
    .smart-tab-label-text-container {
      font-size: 16px;
      font-weight: 300;
      text-transform: none;
    }
  }
}

smart-tabs.window-tabs {
  --smart-tabs-default-width: 100%;
  --smart-tabs-default-height: auto;
  padding: 0;

  .smart-container {
    .smart-tabs-header-section {
      background-color: transparent;
      border: none;
    }
    .smart-tabs-content-section {
      border: none;
      overflow-y: scroll;
      margin-top: 20px;
    }
    .smart-tab-strip {
      border-bottom: 1px solid #999;
    }
  }
}

#accordionAddresses .smart-container,
#accordionAddresses .smart-label,
#accordionAddresses .smart-accordion-item,
#accordionAddresses .smart-accordion-item-header,
#accordionAddresses .smart-accordion-item-content,
#accordionAddresses .smart-content-container {
  padding: 0 !important;
  margin: 0 !important;
  background: none !important;
  border: none !important;
}

#accordionAddresses .smart-accordion-item-header {
  border-bottom: 0.3px solid rgba(112, 112, 112, 0.4) !important;
}

smart-button[theme=custom].smart-element {
  --smart-button-padding: 9px 25px;
  --smart-button-opacity-hover: 1;
  --smart-button-opacity-focus: 1;
  --smart-button-opacity-active: 1;
  min-width: 125px;
  height: $form-control-height;
}

smart-button[theme=custom].smart-element.cancel,
smart-button[theme=custom].smart-element.cancel:hover {
  background-color: transparent;
  border-color: var(--smart-primary);
  color: var(--smart-primary);
  --smart-ui-state-color-hover: var(--smart-primary);
  :before {
    margin-right: 8px;
    font-family: "FontAwesome";
    content: '\f00d';
  }
}

.btn-cancel:hover {
  cursor: pointer;
}

smart-button[theme=custom].plus > button.smart-button,
smart-button[theme=custom].plus > button.smart-button:hover {
  background-color: transparent;
  color: var(--smart-primary);
  --smart-ui-state-color-active: none;
  padding-left: 0;
}


button.smart-button[type]:hover {
  background-color: transparent;
}

.bg-body {
  background-color: #f8f8f9 !important;
}
.border {
  border-color: #707070 !important;
  border-radius: 5px !important;
}
.dialog-header-danger {
  background-color: $primary-red !important;
}
.border-danger {
  border-color: $primary-red !important;
}

.column-header-text, .form-label-text {
  font-size: 14px;
}

.column-header-text {
  font-weight: bold
}

smart-button {
  text-transform: inherit;
  letter-spacing: inherit;
  --smart-button-padding: 4px 18px;
  font-weight: bold !important;
  height: $form-control-height;
}

smart-button.primary {
  background-color: $primary-red;
  border-color: $primary-red;
  &:hover {
    background-color: darken($primary-red, 3%);
    color: #ffffff;
  }
}

.smart-form-tab-strip > div.selected {
  border-bottom-color: $primary-red !important;
}

.text-muted {
  color: #707070;
}

.top-container {
  height:100px;
  padding: 30px 25px 0 25px;
  border-bottom: 1px solid $primary-red;
  background: #ffffff;
}

.text-version {
  color: #707070;
  font-size: 12px;
}

a.disabled {
  color: #c4c5c6 !important;
  pointer-events: none !important;
}

.grey-text {
  color: #c4c5c6;
}

.border-grey {
  border-color: #c4c5c6 !important;
}

.bottom-container {
  height: 120px;
  background: #EAEAEA;
}

smart-card.basic-card {
  border-top-left-radius: 8px;
  border-top-right-radius: 6px;
}

smart-card.basic-card .card-content,
smart-card.basic-card .card-action {
  box-sizing: border-box;
  padding: 20px;
  color: #445259;
  background-color: rgba(130,130,130,0.2);
  border-bottom: 1px solid rgba(255,255,255,1);
}

smart-card.basic-card .card-title {
  font-size: 18px;
  font-weight: bold;
  color: #445259;
}

smart-card.basic-card a {
  color: $primary-red;
  transition: color .3s ease;
  text-transform: uppercase;
  border-bottom: none;
  text-decoration: none;
  margin-right: 20px;
}

smart-card.basic-card a:hover {
  color: #333333;
}

.smart-badge {
  position: relative;
  z-index: 1;
  float: right;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.btn-circle:hover {
  background-color: #999999 !important;
}

.mnu-header-text {
  font-size: 20px;
  font-weight: bold;
  color: #445259;
}

smart-breadcrumb {
  width: 100%;
  padding: 0;
  border: 0;
}
.breadcrumb-item {
  font-size: 12px;

}

.grid-column-header-text {
  text-align: left;
  font: normal normal 600 14px/19px;
  font-weight: 600;
  letter-spacing: 0;
  color: #445259;
  opacity: .95;
}

.smart-calendar .smart-calendar-title {
  background-color: $primary-red;
}
div.smart-calendar-cell[aria-selected="true"] {
  background-color: $primary-red !important;
  border-color: $primary-red !important;
}

div.smart-calendar-cell:focus {
  outline: none !important; /* Entfernen Sie die Standard-Umrisslinie */
  box-shadow: 0 0 5px 2px rgba(0, 0, 255, 0.5); /* Fügen Sie einen blauen Schatten hinzu */
}

.form-control, .smart-date-time-picker, .smart-date-time-input {
  background: #f8f8f9;
  height: $form-control-height !important;
  border: none;
  font-size: 14px !important;
}

.form-control:focus {
  box-shadow: none !important;
  background-color: #f8f8f9;
}

input[disabled],
input[disabled].form-control,
textarea[disabled],
textarea[disabled].form-control,
.disabled-field,
.text-input-disabled {
  background-color: #EAEAEA;
  border-color: #EAEAEA !important;
  border-radius: 5px;
}

smart-number-input[disabled] {
  // overwrite opacity to preserve full text color
  opacity: 1;

  border: none !important;
}

a.disabled {
  color: #c4c5c6 !important;
  pointer-events: none !important;
}

.smart-switch-button[disabled] .smart-thumb {
  cursor: default;
}

.bg-grey {
  background-color: #EAEAEA !important;
}

.smart-date-time-picker .smart-date-time-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 33px !important;
}

.smart-date-time-picker.border-danger .smart-date-time-input {
  border-top-color: $primary-red !important;
  border-bottom-color: $primary-red !important;
  /* All date-time-inputs form fields are red background if invalid
  background-color: rgba(204, 15, 60, 0.05) !important;
   */
}

.smart-numeric-text-box {
  background-color: #f8f8f9 !important;
  height: 34px !important;
}

.smart-control-select {
  --smart-background: #f8f8f9 !important;
  --smart-ui-state-hover: #f8f8f9 !important;
  --smart-primary-color:  #f8f8f9 !important;
  --smart-ui-state-color-active:  #f8f8f9 !important;
  --smart-surface:  #f8f8f9 !important;
  --smart-border:  #f8f8f9 !important;
  height: 34px;
  border: none;
  font-size: 14px !important;
}

.smart-control-select .smart-buttons-container {
  /*margin-left: 1px !important;*/
}

.smart-date-input, .smart-date-time-picker {
  padding-right: 0 !important;
}

smart-window .smart-header {
  padding: 0;
  font-size: 16px;
  font-weight: 600;
}
smart-window .smart-header-section {
  padding: 0 15px !important;
  height: 40px;
  border: none;
  background-color: #707070;
}

/* Bootstrap 5 overwrites */

.text-primary {
  color: var(--bs-red) !important;
}

.border-secondary {
  border-color: lightgray !important;
}

.smart-grid-column-menu smart-menu-item:has(.smart-icon-starts-with),
.smart-grid-column-menu smart-menu-item:has(.smart-icon-ends-with),
.smart-grid-column-menu smart-menu-item:has(.smart-icon-does-not-contain) {
  display:none;
}

.rowDelBtn {
  height: 24px;
  width: 60px;
  margin: 0;
}
.rowDelBtn > button.smart-container {
  padding-top: 0;
  margin: 0;
}

.smart-window .smart-content-container > .smart-footer {
  background: none !important;
}

smart-window#dataManagerWindow .smart-footer {
  display: flex;
  justify-content: left;
}

smart-window#dataManagerWindow .smart-footer button {
  height: $form-control-height;
}

/* grid pager float right */
smart-pager {
  .smart-container {
    width: auto !important;
    float: right !important;
  }
}

.smart-filter-input-container[data-field="number"],
.smart-filter-input-container[data-field="house_number"],
.smart-filter-input-container[data-field="updated_at"],
.smart-filter-input-container[data-field="updated_by"],
.smart-filter-input-container[data-field="action"] {
  display: none !important;
}

input[type="file"]::file-selector-button {
  background-color: $primary-red !important;
  color: #f8f8f9 !important;
  font-weight: bold !important;
}

a.link-danger:hover {
  cursor: pointer;
}

/* default grid pager */
div[smart-id="headerPager"] {
  /*display: none;*/
  width: 50% !important;
  float: right !important;
  height: 50px !important;
  .smart-pager {
    overflow: hidden !important;
  }
}
div[smart-id="footerPager"] {
  float: right !important;
  height: 50px !important;
}

.col-md-center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.col-centered {
  display:inline-block;
  float:none;
}

input-euro {
  position: absolute;
  right: 10px;
  top: 8px;
  color: #C4C5C6;
}

smart-tooltip {
  font-size: 10px !important;
  &.tooltip-is-invalid {
    --smart-tooltip-arrow-color: #{$primary-red};
    --smart-tooltip-padding: 8px 10px;
    + * {
      fa-icon {
        &:hover {
          cursor: pointer;
        }
      }
    }
    &.left {
      .smart-tooltip-content {
        &:after {
          margin-left: 50px;
        }
      }
    }
    .smart-tooltip-content {
      border: none;
      border-radius: unset;
      background-color: $primary-red;
      color: #f8f8f9;
      &:after {
        margin-left: -50px;
      }
    }
  }
}

.grid-row-buttons {
  padding-right: 8px;
  smart-button {
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
    &:not(:last-child) {
      margin-right: 4px;
    }
    button {
      padding: 0;

      i {
        font-family: FontAwesome;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.dropdown-disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.delete-confirm-window {
  --smart-window-default-height: auto;
}

.cdk-overlay-container {
  // needs to be more than smart window z-index
  // generally problematic to use a smart-window and angular material overlay (autocomplete) due to z-index conflicts
  // and mat dialog and smart datepicker on the other hand (Konditionsverwaltung)
  z-index: 10000;
}

.smart-drop-down, .smart-popup, .smart-input-drop-down-menu {
  // in Konditionsverwaltung a Datepicker is opened within the mat-dialog
  z-index: 10001;
}

.cursor-pointer {
  cursor: pointer;
}

.field-placeholder-wrapper {
  position: relative;
  .field-placeholder {
    position: absolute;
    bottom: 10px;
    right: 3px;
    z-index: 2;
    line-height: 19px;
    display: inline-block;
    width: 30px;
    text-align: center;

    &.auto-width {
      width: unset;
      right: 10px;
    }

    &.light {
      color: #c4c5c6;
    }
  }
  input, smart-number-input[drop-down-button-position=right] .smart-input {
    width: 100%;
    padding-right: 30px;
    border-top-right-radius: var(--smart-border-top-right-radius);
    border-bottom-right-radius: var(--smart-border-bottom-right-radius);
  }
}

.smart-tree[selection-mode=checkBox] smart-tree-item[selected][focus]>.smart-tree-item-label-container:before,
.smart-tree[selection-mode=checkBox] smart-tree-items-group[selected][focus]>.smart-tree-item-label-container:before{
  display: none;
}

smart-tree {
  width: auto;
  border: none;
}

.smart-scroll-viewer {
  width: 100%;
  .smart-scroll-viewer-content-container {
    width: 100%;
  }
}

/* overwrite smart-scroll-viewer in smart-phone-input */
.smart-phone-input-drop-down-menu,
.smart-input-drop-down-menu {
  width: auto;
}

.smart-toggle-box .smart-label {
  line-height: 18px;
}

smart-date-time-picker {
  --smart-editor-selection: #{$primary-red};
}

smart-phone-input > div > div.smart-flag-box {
  /* hidden dropdown for choice country list
  display: none !important;
   */
  background-color: transparent !important;
}

.smart-phone-input-drop-down-menu {
  a {
    pointer-events: none;
  }
}

smart-phone-input > div > input {
  width: 100% !important;
}

/* hidden content: var(--smart-icon-phone) */
.smart-phone-input .smart-container:after {
  display: none;
}

.text-transparent {
  color: transparent;
}

.required-field-hint {
  font-style: italic;
  margin: 0 3px 11px 0;
}

smart-combo-box[readonly] {
  background-color: #EAEAEA !important;
  --smart-background: #EAEAEA !important;
  color: rgb(33, 37, 41) !important;
  pointer-events: none !important;
  border: none !important;
  .smart-action-button {
    width: 100% !important;
    border: 1px solid #EAEAEA !important;
    border-top-right-radius: var(--smart-border-top-right-radius);
    border-bottom-right-radius: var(--smart-border-bottom-right-radius);
    background-color: #EAEAEA !important;
  }
  .smart-drop-down-button {
    display: none;
  }
}

smart-check-box[name="days"]:first-child > div > span.smart-input,
smart-check-box[name="badge"]:first-child > div > span.smart-input {
  margin-left: 0 !important;
}

.smart-calendar {
  height: auto !important;
}

.smart-calendar-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
  border-left: 1px solid #C4C5C6 !important;
  border-right: 1px solid #C4C5C6 !important;
  border-bottom: 1px solid #C4C5C6 !important;
}

.smart-date-time-drop-down {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
}

.smart-calendar-date-view-container, .smart-calendar-body {
  height: auto !important;
  min-height: 240px;
}

.smart-calendar-footer {
  border-bottom: none !important;
  .reset-button, .today-button {
    display: inline-block;
    background-repeat: no-repeat;
    width: 18px;
    height: 20px;
    cursor: pointer;
  }

  .reset-button {
    background-image: url('data:image/svg+xml,<svg role="img" aria-labelledby="svg-inline--fa-title-OpwuP1h53FA1" data-prefix="fas" data-icon="calendar-xmark" class="svg-inline--fa fa-calendar-xmark fa-1x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><title id="svg-inline--fa-title-OpwuP1h53FA1">Datum löschen</title><path fill="currentColor" d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM305 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47z"></path></svg>');
  }

  .today-button {
    background-image: url('data:image/svg+xml,<svg role="img" aria-labelledby="svg-inline--fa-title-Mn8G3NTlIvod" data-prefix="fas" data-icon="calendar-check" class="svg-inline--fa fa-calendar-check fa-1x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><title id="svg-inline--fa-title-Mn8G3NTlIvod">Datum auf Heute setzen</title><path fill="currentColor" d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z"></path></svg>');
  }
}

smart-button[theme=custom].smart-element.btn-disabled {
  background-color: #C4C5C6;
  border-color: #C4C5C6;
  color: white;
  pointer-events: none;
}

/* CSS fallback calc() for safari */
smart-grid {
  min-height: -webkit-calc(100%) !important;
}

.snack-waiting {
  position: fixed !important;
  top: -55% !important;
  background-color: rgba(112, 112, 112, 0.4);
  .mdc-snackbar__surface {
    text-align: center;
    padding: 0 16px 0 4px;
    white-space: pre-wrap;
    .mdc-snackbar__label {
      &:before {
        margin-right: 12px;
        position: relative;
        top: 2px;
        display: inline;
        font-family: "FontAwesome";
        font-size: 22px;
        content: "\f017";
      }
    }
  }
}

.mat-mdc-snack-bar-container.error .mdc-snackbar__surface {
  background-color: $primary-red;
  white-space: pre-wrap;
}

.smart-page-size-selector {
  border: none !important;
}
.smart-page-size-selector .smart-input {
  font-weight: bold !important;
}
span.smart-pager-page-index-selector.smart-selected {
  background-color: transparent;
  box-shadow: none;
  color: $primary-red;
  font-weight: bold;
  text-decoration: underline;
}

.mat-mdc-tooltip {
  &.type-info, &.type-error, &.menu-info {
    .mdc-tooltip__surface {
      color: #445259 !important;
      background-color: #DDDDDD !important;
      transform: translateX(calc(50% - 15px));
      max-width: 250px;
      font-size: 10px !important;
      border-radius: 0 !important;
      box-shadow: var(--smart-elevation-6);
      padding: 6px 10px;
      overflow: unset;

      &:after {
        content: '';
        border-left: var(--smart-tooltip-arrow-width) solid transparent;
        border-right: var(--smart-tooltip-arrow-width) solid transparent;
        border-bottom: var(--smart-tooltip-arrow-width) solid #DDDDDD;
        border-top: initial;
        top: -6px;
        bottom: calc(100% + var(--smart-tooltip-arrow-width));
        transform: translateX(var(--smart-tooltip-arrow-translate));
        width: 0;
        height: 0;
        position: absolute;
        left: 9px;
      }
    }
  }

  &.menu-info {
    .mdc-tooltip__surface {
      transform: translateX(5px);
      &:after {
        transform: rotate(270deg);
        top: calc(50% - 3px);
        left: -9px;
      }
    }
  }

  &.type-error {
    .mdc-tooltip__surface {
      color: #f8f8f9 !important;;
      background-color: $primary-red !important;;

      &:after {
        border-left: var(--smart-tooltip-arrow-width) solid transparent;
        border-right: var(--smart-tooltip-arrow-width) solid transparent;
        border-bottom: var(--smart-tooltip-arrow-width) solid $primary-red;
      }
    }
  }

  .mdc-tooltip__surface {
    // enable line breaks
    white-space: pre-line;
  }
}

.smart-grid.smart-container .smart-grid-pager, .smart-grid>.smart-container .smart-grid-pager {
  --smart-pager-default-height: #{$form-control-height};
}

app-grid-search {
  position: relative;
  z-index: 2;
  display: block;
}
.w-auto-grid-search {
  width: auto;
  float: left;
  overflow: visible;
  max-width: 630px;
}

.mat-mdc-autocomplete-panel, .mat-mdc-menu-panel {
  border-radius: 0;
  .mat-mdc-option, .mat-mdc-menu-item {
    font-size: 14px;
    min-height: 39px;
  }
  .mat-mdc-menu-item {
    &:hover, &.active {
      background-color: #dddddd;
    }
    .mat-mdc-menu-item-text {
      font-size: 14px !important;
    }
  }
}



// @Todo: can be removed once the new app-autocomplete-select is implemented everywhere
.field-loading-wrapper {
  position: relative;
  .mat-mdc-progress-spinner {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.grid-search-row {
  &.grid-collapse-row {
    margin-top: 20px;
  }
  margin-top: 30px;
}

// the top and margin-top values are in relation to/with the grid-search .filter-row height
// Remove in v2
.grid-search-offset-top {
  position: relative;
  top: -45px;
  clear: both;
}

.field-hint {
  font-size: 11px;
  margin-left: 2px;
  color: #707070;
}

// app-autocomplete-select dropdown styles
.mat-mdc-option.load-more-btn {
  font-size: 14px;

  .mdc-list-item__primary-text {
    display: block;
    width: 100%;
    text-align: center;
    color: $primary-red;
  }
}

.smart-list-items-group-label {
  font-weight: bold;
}

.disabled-field {
  height: $form-control-height;
  border-radius: 5px;
  padding: .375rem .75rem;
  font-size: 14px;
  line-height: 1.5;
  overflow: auto;
}

.color-light-grey {
  color: #707070;
}

#functionsGrid div[role="checkbox"] .smart-input,
#functionsGrid div[data-field="primary"] .smart-input {
  border: 1px solid #999999;
}

::placeholder {
  opacity: 1 !important;
  color: #C4C5C6 !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: rgb(248, 248, 249) !important;
}

.stInfoPrimary {
  position: fixed;
  div:first-of-type {
    .smart-container {
      all: unset;
      padding: 0;
      margin: 0;
      height: 30px;
    }
  }
  div:last-of-type {
    .smart-tooltip-content {
      padding: 0 4px 0 4px;
      color: #445259;
      font-size: 10px;
      font-weight: normal;
      box-shadow: none;
      border-color: transparent;
      background: #DDDDDD;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}

.default-textarea-field {
  resize:none;
  overflow:hidden;
  width: 100%;
  height:70px !important;
}

.link-into-div {
  position: relative;
  top: 3px;
}

.truncate {
  max-width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// this should be part of bootstrap lib, but for some reason not available
.ml-auto {
  margin-left: auto;
}

smart-scroll-viewer {
  // this value depends on smart-tree height in tree-dropdown-list scss and removes the scrollbar
  --smart-list-box-default-height: 305px;
}

// Konditionsvereinbarung "Berater" option "Import" comes from data import, should be visible as selected, but not displayed in list
smart-list-item[value="Import"] {
  display: none;
}

// Customer Dropdown sitenav
.smart-list-box-filter-input-container > input {
  border: none;
}

smart-drop-down-list[theme=custom].smart-element {
  height: $form-control-height;
}

smart-drop-down-list[theme=subsidiaries].smart-element,
smart-drop-down-button[theme=subsidiaries].smart-element {
  height: 36px;

  /* #{$primary_red} */

  .smart-button-container {
    border-bottom: 1px solid rgba(68, 82, 89, 1);
  }

  --smart-primary: rgba(68, 82, 89, 1);
  --smart-primary-color: rgba(255, 255, 255, 1);

  --smart-background-color: rgba(68, 82, 89, 1); /* Dropdown list text color */
  --smart-background: rgba(248, 248, 249, 1); /* Dropdown list background color */
  --smart-ui-state-border-selected: rgba(68, 82, 89, 1); /* Dropdown list border color selected */
  --smart-ui-state-color-selected: rgba(224, 224, 224, 1); /* Dropdown list text color selected */
  --smart-ui-state-selected: rgba(68, 82, 89, 1); /* Dropdown list background color selected */

  --smart-surface-color: rgba(68, 82, 89, 1); /* Dropdown header text color */
  --smart-surface: transparent; /* Dropdown header background color */
  --smart-ui-state-active: transparent; /* Dropdown header background color active */
  --smart-ui-state-color-active: rgba(68, 82, 89, 1);
  --smart-ui-state-hover: transparent; /* Dropdown header background color hover */
  --smart-ui-state-color-hover: rgba(68, 82, 89, 1); /* Dropdown header text color hover */
  --smart-ui-state-border-hover: transparent; /* Dropdown header border color hover */
  --smart-outline: transparent; /* Dropdown header border color ??? focus,active */
  --smart-border-bottom-left-radius: 0;
  --smart-border-bottom-right-radius: 0;

  --smart-border: transparent;
  --smart-border-radius: 0;

  /*
  --smart-border-top-left-radius: 5px;
  --smart-border-top-right-radius: 5px;

  --smart-ui-state-border-active: rgba(68, 82, 89, 1);
  --smart-ui-state-border-focus: rgba(68, 82, 89, 1);
  --smart-ui-state-color-focus: rgba(224, 224, 224, 1);
  --smart-ui-state-focus: #{$primary_red};
  */
}

/* Button filter group top on overview pages */
smart-button-group {
  /* Overwrite theme 'custom' styles */
  --smart-ui-state-active: rgba(112, 112, 112, 1) !important;
  --smart-ui-state-border-active: rgba(112, 112, 112, 1) !important;
  --smart-primary: rgba(112, 112, 112, 1) !important;
  border: none;
  font-size: 14px;
  .smart-button-group-item {
    margin-right: 16px;
    border-radius: 24px !important;
    text-transform: capitalize;
    letter-spacing: normal;
    background-color: rgba(224, 224, 224, 1);
    &[active] {
      background-color: $mat-dialog-header-background !important;
      color: #ffffff !important;
    }
  }
}

#functionsGrid {
  .smart-pager {
    padding-bottom: 20px !important;
  }
}

.updated-at-label {
  font-size: 11px;
}

.prefix {
  color: #707070;
}
